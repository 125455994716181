@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.cemetery-info {
  border-bottom: 1px solid $color-black-10;
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
  padding-bottom: 24px;

  @include desktop() {
    gap: 24px;
    padding-bottom: 27px;
  }
}

.cemetery-info__contacts {
  @include reset-list();
  display: grid;
  gap: 12px;
  justify-content: end;

  @include desktop() {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px 40px;
    justify-content: flex-end;

    @supports not (translate: 10px) {
      // safari below 14.1, safari ios below 14.5
      gap: 0;
    }
  }
}

.cemetery-info__contacts-item {
  @include reset-list-item();

  @include desktop() {
    @supports not (translate: 10px) {
      // safari below 14.1, safari ios below 14.5
      margin-bottom: 12px;
      margin-left: 40px;
    }
  }
}

.cemetery-info__contacts-item-link {
  display: grid;
  gap: 16px;
  grid-template-columns: 20px max-content;
}

.cemetery-info__contacts-item-icon {
  color: $color-black-30;
  display: block;
  height: 20px;
  width: 20px;
}

.cemetery-info__contacts-item-text {
  @include textBody($font-family: $font-second);
}
