@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.person-form {
  display: grid;
  gap: 16px;

  @include tablet() {
    gap: 28px;
  }
}

.person-form_deed-owner {
  @include tablet() {
    padding-bottom: 8px;
  }
}

.person-form__title-wrapper_deed-owner {
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
  word-break: break-word;

  @include tablet() {
    gap: 16px;
    grid-template-columns: none;
  }

  @media (max-width: 330px) {
    .checkbox__label {
      gap: 8px;
    }
  }
}

.person-form__title {
  @include reset-heading();
  @include textSubtitle($weight: $font-weight-bold);
}

.person-form__inputs-wrapper {
  display: grid;
  gap: 24px;

  @include tablet() {
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

.person-form__address-wrapper {
  display: grid;
  gap: 24px;

  @include tablet() {
    grid-column: 1 / -1;
    grid-template-columns: 460px 1fr;
  }
}

.person-form__row {
  @include tablet() {
    grid-column: 1 / -1;
  }
}

.person-form__phone-wrapper {
  display: grid;
  gap: 8px;
}

.person-form__phone-text {
  @include textCaption($weight: $font-weight-regular, $color: $color-black-50);
  @include reset-description();

  @include tablet() {
    max-width: 240px;
  }
}

.person-form__phone-text_hidden {
  opacity: 0;
  pointer-events: none;
}
