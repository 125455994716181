@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";

.arrow-button {
  @include focus();
  @include reset-button();
}

.arrow-button__icon {
  color: $color-black-30;
  display: block;
}

.arrow-button_previous {
  transform: rotate(-180deg);
}
