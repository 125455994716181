@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/padding.scss";

.lot-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(var(--vh, 1vh) * 100);
}

.lot-layout__scrollable-block {
  display: grid;
  overflow-y: auto;
}

.lot-layout__content {
  display: grid;
  gap: 32px;
  grid-template-rows: 1fr auto;

  @include tablet() {
    gap: 40px;
  }
}

.lot-layout__content_with-button {
  grid-template-rows: 1fr auto auto;
}

.lot-layout__find-button {
  justify-self: center;
}
