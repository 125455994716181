@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

$scroll-button-height: 36px;

.map-lots {
  background-color: $color-black-0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  @include laptop() {
    width: 624px;
  }
}

.map-lots__footer {
  margin-top: auto;
}

.map-lots__scroll {
  animation: fadeIn $transition-time-long linear;
  background: $color-black-0;
  border-radius: 18px;
  bottom: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22), 0 2px 8px rgba(0, 0, 0, 0.14);
  color: $color-theme-primary;
  left: 16px;
  margin-top: -$scroll-button-height;
  min-height: $scroll-button-height;
  position: sticky;
  text-transform: initial;
  width: 36px;
  z-index: 10;

  svg {
    transform: rotate(180deg);
  }

  @include tablet() {
    bottom: 32px;
    left: 48px;
  }

  @include laptop() {
    left: 36px;
  }

  @media (max-width: $tablet-width - 1px) {
    transform: translateY(-14px);
  }
}
