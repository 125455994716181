@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.map-filters {
  background-color: $color-black-0;
  box-sizing: border-box;
  width: 100%;
}

.map-filters__wrapper {
  box-sizing: border-box;
  display: grid;
  gap: 28px;
  max-width: 600px;
  padding: 8px 16px 20px;
  width: 100%;

  @include laptop() {
    align-items: center;
    gap: 20px;
    grid-auto-flow: column;
    grid-template-columns: unset;
    justify-content: start;
    max-width: 100%;
    padding: 12px 0 24px 32px;
  }

  @include desktop() {
    gap: 28px;
  }
}

.map-filters__item {
  align-items: end;
  display: grid;
  gap: 12px;

  @include laptop() {
    align-items: center;
    grid-auto-flow: column;
  }
}

.map-filters__mobile-two-columns {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;

  @include laptop() {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }
}

.map-filters__select {
  @include laptop() {
    min-width: 160px;
  }

  @include desktop() {
    min-width: 180px;
  }
}

.map-filters__item-label {
  @include textBody($weight: $font-weight-bold);
  word-break: break-word;
}

.map-filters__range-dropdown {
  padding: 8px 12px;
  width: auto;
}

.map-filters__range-dropdown_mobile {
  left: auto;
  right: 0;
}

.map-filters__apply-button {
  word-break: break-word;
  @include laptop() {
    display: none;
  }
}

.map-filters__clear-button {
  word-break: break-word;
}
