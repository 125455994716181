@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";

.cemetery-logo {
  @include focus();
  @include reset-link();
  align-content: start;
  align-items: center;
  display: grid;
}

.cemetery-logo__img {
  height: 34px;
}

.cemetery-logo_side {
  color: $color-theme-primary;
  gap: 8px;
  grid-auto-flow: column;
  justify-items: start;
  max-width: 230px;

  .cemetery-logo__img {
    max-height: 50px;
    max-width: 150px;
    width: auto;
  }

  .cemetery-logo__img_with-text {
    height: 34px;
    width: 34px;
  }
}

.cemetery-logo_medium {
  display: none;

  @include tablet() {
    display: grid;
  }
}

.cemetery-logo_bottom {
  align-content: center;
  color: $color-black-5;
  gap: 11px;
  justify-items: center;
  min-height: 120px;

  @include tablet() {
    min-height: 200px;
  }

  .cemetery-logo__img {
    height: 120px;
    max-width: 140px;
    width: auto;

    @include tablet() {
      height: 200px;
      max-width: 200px;
    }
  }

  .cemetery-logo__img_with-text {
    height: 70px;
    max-width: 70px;

    @include tablet() {
      height: 110px;
      max-width: 100px;
    }
  }
}

.cemetery-logo_small-side {
  display: grid;

  @include tablet() {
    display: none;
  }
}

.cemetery-logo_small_in-footer {
  height: max-content;
  padding: 3px 1px;
}

.cemetery-logo_medium_in-footer {
  padding: 3px 0;
}

.cemetery-logo_colored {
  color: $color-theme-primary;
}

.cemetery-logo_shadowed {
  .cemetery-logo__img {
    filter: $readability-filter;
  }
}
