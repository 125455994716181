@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.labeled-value {
  align-items: center;
  display: inline-grid;
  gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
}

.labeled-value__label {
  @include textBody($weight: $font-weight-regular, $color: $color-black-45);

  @include tablet() {
    @include textAccent();
  }
}

.labeled-value__value {
  @include textBody($weight: $font-weight-bold);
  word-break: break-word;

  @include tablet() {
    @include textAccent($weight: $font-weight-bold);
  }
}
