@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.map-filters-wrapper {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 8px 16px 16px;
  position: relative;

  @include tablet() {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.map-filters-wrapper__toggle-icon {
  background: $color-black-5;
  border: 1px solid $color-black-5;
  border-radius: 20px;
  color: $color-theme-primary;

  svg {
    height: 24px;
    width: 24px;
  }
}

.map-filters-wrapper__toggle-icon_open {
  background: rgba($color-theme-assist-rgb, 0.12);
  border-color: $color-theme-primary;
  color: $color-theme-primary;
}

.map-filters-wrapper__modal {
  animation: fadeIn 0.2s linear;
  background-color: rgba($color: $color-black, $alpha: 0.3);
  display: none;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  position: fixed;
  top: 120px;
  width: 100vw;
  z-index: 50;

  @include tablet() {
    top: 72px;
  }
}

.map-filters-wrapper__modal_shown {
  display: block;
}
