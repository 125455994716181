@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.lot-card__wrapper {
  background: $color-black-0;
  display: grid;
  gap: 14px;
  outline: none;
  padding: 12px;
  position: relative;
  z-index: 0;

  &::before {
    background: linear-gradient(
        0deg,
        rgba($color: $color-theme-assist-rgb, $alpha: 0.12),
        rgba($color: $color-theme-assist-rgb, $alpha: 0.12)
      ),
      $color-black-0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity $transition-time;
    width: 100%;
    z-index: -1;
  }

  @include tablet() {
    gap: 28px;
    grid-template-columns: 140px 1fr;
    max-width: 100%;
    padding: 20px 32px;
  }

  @include laptop() {
    cursor: pointer;
    grid-template-columns: 180px minmax(auto, 352px);

    &:hover::before,
    &:focus::before {
      opacity: 1;
    }
  }
}

.lot-card__wrapper_selected::before {
  @include laptop() {
    opacity: 1;
  }
}

.lot-card__img-container {
  display: grid;
  position: relative;
}

.lot-card__img-info {
  bottom: 20px;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: space-between;
  left: 16px;
  position: absolute;
  width: 90.5%;

  @include tablet() {
    display: none;
  }
}

.lot-card__img-info_no-img {
  .lot-card__img-info-value {
    color: $color-black-75;
  }
}

.lot-card__img-info-value {
  @include textSubtitle($weight: $font-weight-bold, $color: $color-black-5);
}

.lot-card__img {
  border-radius: 8px;
  height: calc(100vw - 24px);
  object-fit: cover;
  width: calc(100vw - 24px);

  @include tablet() {
    height: 140px;
    width: 140px;
  }

  @include laptop() {
    height: 180px;
    width: 180px;
  }
}

.lot-card__information-buttons {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 12px;
  text-align: center;
  word-break: break-word;

  @include tablet() {
    align-content: center;
    gap: 12px;
    grid-area: buttons;
    grid-template-columns: 1fr;
  }
}

.lot-card__information-buttons_laptop {
  align-content: center;
  bottom: 20px;
  gap: 12px;
  grid-area: unset;
  grid-template-columns: repeat(2, 170px);
  padding: 0;
  position: absolute;
  right: 32px;
}

.lot-card__content {
  @include tablet() {
    align-content: start;
    display: grid;
    gap: 12px;
    grid-template-areas:
      "details buttons"
      "price buttons";
    grid-template-columns: 1fr 170px;
  }

  @include laptop() {
    grid-auto-rows: max-content;
    grid-template-areas: none;
    grid-template-columns: auto;
    padding-bottom: 54px;
  }
}

.lot-card__details-container {
  @include tablet() {
    display: grid;
    gap: 8px;
    grid-area: details;
  }

  @include laptop() {
    grid-area: unset;
  }
}

.lot-card__details-title,
.lot-card__price {
  display: none;

  @include tablet() {
    @include reset-heading();
    @include textSubtitle($weight: $font-weight-bold);
    display: block;
  }
}

.lot-card__price {
  @include tablet() {
    grid-area: price;
  }

  @include laptop() {
    grid-area: unset;
  }
}
