@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.no-lots {
  align-content: center;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 40px;
  height: 100%;
  justify-items: center;
  margin: 0 auto;
  max-width: 292px;
  min-height: 430px;
  padding: 16px 16px 70px;
}

.no-lots__img {
  color: $color-text-disabled;
  display: block;
  height: 100px;
  width: 100px;
}

.no-lots__prompt {
  align-content: start;
  display: grid;
  gap: 16px;
  justify-items: center;
  padding-bottom: 8px;
  word-break: break-word;
}

.no-lots__title {
  @include reset-heading();
  @include textHeadline3($color: $color-text-caption, $weight: $font-weight-bold);
  opacity: 0.85;
  text-align: center;
}

.no-lots__text {
  @include reset-description();
  @include textAccent($color: $color-black-50);
  text-align: center;
}

.no-lots__btn {
  width: 200px;
  word-break: break-word;
}
