@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.thumb-item {
  @include reset-list-item();
  border-radius: 2px;
  height: 90px;
  overflow: hidden;
  width: 90px;

  @include tablet() {
    border-radius: 4px;
    height: 120px;
    width: 120px;
  }
}

.thumb-item__button {
  @include reset-button();
  height: 100%;
  opacity: 0.5;
  transition: opacity $transition-time;
  width: 100%;

  &:hover:not(.thumb-item__button_active),
  &:focus:not(.thumb-item__button_active) {
    opacity: 0.7;
  }

  @include tablet() {
    opacity: 0.3;
  }
}

.thumb-item__button_active {
  cursor: unset;
  opacity: 1;
}

.thumb-item__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
