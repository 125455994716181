@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.image-viewer {
  align-content: start;
  display: grid;
  gap: 16px;

  @include tablet() {
    gap: 32px;
  }

  @include laptop() {
    height: 100%;
    width: 100%;
  }
}

.image-viewer__images {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: column;

  @include laptop() {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 276px;
    position: relative;
    width: 100%;
  }
}

.image-viewer__images-list {
  @include reset-list();
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  overflow: auto;
  padding: 0 0 0 16px;

  &::after {
    content: "";
    display: block;
    height: 100%;

    // fixes safari padding
    margin-left: -8px;
    width: 16px;
  }

  @include laptop() {
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 0;
    width: 100%;

    &::after {
      display: none;
    }
  }
}

.image-viewer__images-list_mobile {
  gap: 100px;
  overflow: hidden;

  .image-viewer__images &.image-viewer__images-list {
    padding: 0 16px;
  }

  .image-viewer__images-item {
    max-width: none;
    width: calc(100vw - 32px);
  }
}

.image-viewer__images-item {
  @include reset-list-item();
  align-items: center;
  display: flex;
  height: calc((100vw - 32px) * 1.34); // relates to width * aspect ratio
  justify-content: center;
  max-height: calc(
    var(--vh, 1vh) * 100 - 90px - 90px - 16px - 16px
  ); // excludes thumbs height and paddings and gaps
  max-width: calc(
    (var(--vh, 1vh) * 100 - 90px - 90px - 16px - 16px) * 0.74
  ); // relates to height * aspect ratio
  overflow: hidden;
  position: relative;
  width: calc(100vw - 32px);

  @media (max-height: 480px) and (max-width: ($laptop-width - 1)) {
    max-height: calc(
      var(--vh, 1vh) * 100 - 100px - 32px
    ); // excludes thumbs height and paddings and gaps
    max-width: calc(
      (var(--vh, 1vh) * 100 - 100px - 32px) * 0.74
    ); // relates to height * aspect ratio
  }

  @media (max-height: 480px) and (max-width: ($tablet-width - 1)) {
    max-height: calc(
      var(--vh, 1vh) * 100 - 90px - 16px
    ); // excludes thumbs height and paddings and gaps
    max-width: calc(
      (var(--vh, 1vh) * 100 - 90px - 16px) * 0.74
    ); // relates to height * aspect ratio
  }

  @include tablet() {
    height: calc((100vw - 200px) * 1.34); // relates to width * aspect ratio
    max-height: calc(
      var(--vh, 1vh) * 100 - 120px - 100px - 32px - 32px
    ); // excludes thumbs height and paddings and gaps
    max-width: calc(
      (var(--vh, 1vh) * 100 - 120px - 100px - 32px - 32px) * 0.74
    ); // relates to height * aspect ratio
    width: calc(100vw - 200px);
  }

  @include laptop() {
    height: 100%;
    max-height: calc(var(--vh, 1vh) * 100 - 50px - 50px);
    max-width: calc((var(--vh, 1vh) * 100 - 50px - 50px) * 0.75);
    width: 100%;
  }
}

.image-viewer__images-button {
  display: none;

  @include laptop() {
    display: block;
    position: absolute;
    top: 50%;

    &.arrow-button_next {
      right: 196px;
      transform: translateY(-50%);

      @media (min-width: 1360px) {
        right: 260px;
      }
    }

    &.arrow-button_previous {
      left: 196px;
      transform: translateY(-50%) rotate(-180deg);

      @media (min-width: 1360px) {
        left: 260px;
      }
    }
  }
}

.image-viewer__image {
  height: 100%;
  width: 100%;
}

@include laptop() {
  .image-viewer__images-item_hidden-desktop {
    display: none;
  }
}
