@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/focus.scss";

.share-button {
  align-items: start;
  display: grid;
  gap: 12px;
  justify-items: center;
}

.share-button__button {
  @include focus();
}

.share-button__icon {
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  height: 64px;
  overflow: hidden;
  place-items: center;
  width: 64px;
}

.share-button__label {
  @include textCaption();
  text-align: center;
}
