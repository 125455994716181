@import "src/scss/variables.scss";

.section-marker {
  align-items: center;
  background-color: $color-map-marker;
  border: 1px solid $color-black-0;
  border-radius: 5px;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  min-width: 34px;
  padding: 1px 5px;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: 0;
  }

  &::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $color-black-0;
    bottom: -4px;
  }

  &::after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid $color-map-marker;
    bottom: -2.5px;
  }
}

.section-marker__label {
  color: $color-black-5;
  font-size: $font-size-caption;
  font-weight: $font-weight-bold;
}
