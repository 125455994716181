@import "src/scss/animations.scss";
@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/focus.scss";

.map-modal {
  animation: fadeIn $transition-time;
  background-color: $color-black-0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  cursor: initial;
  max-width: 446px;
  min-height: 248px;
  min-width: 426px;
  padding: 6px 6px 6px 0;
  position: relative;
  z-index: 5;

  &::after {
    background-color: $color-black-0;
    border-radius: 0 0 0 30%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    content: "";
    height: 14px;
    left: -7px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 14px;
  }
}

.map-modal__wrapper {
  display: flex;
  width: 100%;
}

.map-modal__photo {
  @include reset-button();
  @include focus();

  &:disabled {
    cursor: default;
  }
}

.map-modal__photo-image {
  border-radius: 6px;
  display: block;
  height: 236px;
  object-fit: cover;
  width: 180px;
}

.map-modal__info {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  max-width: 260px;
  min-width: 240px;
  padding: 14px 16px 8px;
}

.map-modal__title {
  @include reset-description();
  @include textAccent($weight: $font-weight-bold);
  text-align: center;
  word-break: break-word;
}

.map-modal__parameters {
  box-sizing: border-box;
  display: grid;
  gap: 8px 4px;
  grid-template-columns: minmax(80px, auto) 1fr;
  justify-content: start;
  margin: 0;
}

.map-modal__parameter-key {
  @include reset-list-item();
  @include textBody();
}

.map-modal__parameter-value {
  @include reset-list-item();
  @include textBody($weight: $font-weight-medium);
  text-align: right;
  word-break: break-word;
}

.map-modal__parameter-value_bold {
  font-weight: $font-weight-bold;
}

.map-modal__links {
  align-content: start;
  display: grid;
  gap: 8px;
  width: 100%;
}

.map-modal__link {
  text-align: center;
}
