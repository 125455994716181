@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.details-section {
  display: grid;
  gap: 16px;
}

.details-section__title {
  @include reset-heading();
  @include textSubtitle($weight: $font-weight-bold);
}

.details-section__text-wrapper {
  @include reset-description();
  display: grid;
  gap: 12px 16px;
  grid-template-columns: minmax(140px, auto) 1fr;
}

.details-section__label {
  @include textAccent();
  word-break: break-word;
}

.details-section__value {
  @include reset-description();
  @include textAccent($font-weight-medium);
  word-break: break-word;
}

.details-section__value_description {
  white-space: pre-line;
}
