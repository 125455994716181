@import "src/scss/variables.scss";

.dropdown {
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  left: 0;
  position: absolute;
  top: 42px;
  width: 100%;
  z-index: 5;
}
