@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/text.scss";

.range-select {
  box-sizing: border-box;
  position: relative;

  &:focus-within .range-select__button {
    border: 2px solid $color-outline;
  }

  .input__field::placeholder {
    line-height: 20px;
  }
}

.range-select__label {
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.range-select_small {
  .range-select__button,
  .range__input {
    height: 32px;
  }

  .dropdown {
    top: 35px;
  }
}

.range-select__icon {
  color: $color-text-body;
  transition: transform ease-in-out $transition-time;

  .range-select_open & {
    transform: rotate(180deg);
  }
}

.range-select__button {
  background-color: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  outline: none;
  padding: 0 12px;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

.range-select__button-wrapper {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 20px;
  height: 100%;
  text-align: start;
  width: 100%;

  .range-select_open & {
    pointer-events: none;
  }

  .label_placeholder {
    @include textBody($color: $color-black-50);
    top: 9px;
  }
}

.range-select__value {
  @include textBody();
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include tablet() {
  .range-select_desktop-regular {
    .range-select__button {
      border-radius: 8px;
      height: 40px;
      padding: 0 12px;
    }

    .dropdown {
      top: 42px;
    }

    .label_placeholder,
    .input__field::placeholder {
      @include textBody($color: $color-black-50);
    }

    .label_placeholder {
      top: 9px;
    }
  }

  .range-select_desktop-small {
    .range-select__button,
    .range__input {
      height: 32px;
    }

    .dropdown {
      top: 35px;
    }

    .label_placeholder {
      top: 6px;
    }
  }
}
