@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/breakpoints.scss";

.select {
  position: relative;
  width: 100%;
}

.select__button {
  background-color: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

.select__label {
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__button-wrapper {
  @include textBody();
  align-content: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 20px;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  .label_placeholder {
    @include textBody($color: $color-black-50);
    top: 9px;
  }
}

.select__button_invalid {
  border-color: $color-red;

  .label,
  .select__icon,
  .select__value {
    color: $color-red;
  }
}

.select_small {
  .select__button {
    height: 32px;
  }

  .select__dropbox {
    top: 35px;
  }

  .label_placeholder {
    top: 6px;
  }
}

.select__dropbox {
  background-color: $color-black-0;
  border-radius: 4px;
  box-shadow: $basic-shadow;
  display: grid;
  gap: 8px;
  max-height: 205px;
  overflow: auto;
  position: absolute;
  top: 42px;
  width: 100%;
  z-index: 11;

  @media screen and (max-height: 670px) {
    max-height: 150px;
  }
}

.select__dropbox-container {
  display: grid;
}

.select__dropbox-search {
  background-color: $color-black-0;
  border: none;
  position: sticky;
  top: 0;

  &::before {
    background-color: $color-black-10;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: background-color $transition-time ease-in-out;
    width: calc(100% - 24px);
  }

  &:hover {
    .select__icon_search {
      color: $color-black-45;
    }

    &::before {
      background-color: $color-black-45;
    }
  }

  &:not(.input_disabled):focus-within {
    border-color: transparent;
    box-shadow: none;
  }
}

.select__dropbox-search .select__icon_search {
  color: $color-black-20;
  transition: color $transition-time ease-in-out;
}

.select__dropbox-search .select__icon_clear {
  height: 12px;
  width: 12px;
}

.select__dropbox-item {
  @include textBody();
  background-color: $color-black-0;
  border: 0;
  height: 40px;
  margin: 0;
  outline: none;
  padding: 0 12px;
  text-align: start;
}

.select__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select__dropbox-item:disabled {
  color: $color-black-20;
  pointer-events: none;
}

.select__dropbox-item:not(.select__dropbox-item_active) {
  cursor: pointer;
}

.select__dropbox-item:hover,
.select__dropbox-item:focus,
.select__dropbox-item_active {
  background: $color-primary-light-basic;
}

.select__dropbox-item_empty {
  padding-bottom: 20px;
  padding-top: 20px;
}

.select .select__icon_arrow {
  transition: transform ease-in-out $transition-time;
}

.select_open .select__icon_arrow {
  transform: rotate(180deg);
}

.select:hover:not(.select_disabled) {
  .select__button {
    border-color: $color-black-45;
  }

  .select__button_invalid {
    border-color: $color-red;
  }
}

.select:focus-within:not(.select_disabled) .select__button {
  border: 1px solid $color-outline;
  box-shadow: 0 0 0 1px $color-outline;
}

.select_disabled {
  pointer-events: none;

  .select__button {
    background: $color-black-5;
    border-color: $color-black-5;
    cursor: default;
  }

  .select__icon {
    opacity: 0.2;
  }

  .select__value {
    color: $color-black-20;
  }

  .select__label {
    background: transparent;
    color: $color-black-20;
  }
}

.select_borderless {
  background-color: transparent;

  .select__button {
    border: none;
    gap: 4px;
    height: 20px;
    padding: 0 4px;
  }

  .select__dropbox {
    top: 23px;
  }

  &:focus-within:not(.select_disabled) .select__button {
    border: none;
    box-shadow: $button-focus;
  }
}

@include tablet() {
  .select_desktop-regular {
    .select__button {
      border-radius: 8px;
      height: 40px;
      padding: 0 12px;
    }

    .select__dropbox {
      top: 42px;
    }

    .label_placeholder {
      @include textBody($color: $color-black-50);
      top: 9px;
    }
  }

  .select_desktop-small {
    .select__button {
      height: 32px;
    }

    .select__dropbox {
      top: 35px;
    }

    .label_placeholder {
      top: 6px;
    }
  }
}
