@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/breakpoints.scss";

.button {
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  transition: all $transition-time;
}

.button_size_oversize {
  @include textButton-oversize();
  min-height: 52px;
  padding: 15px 10px;
}

.button_size_normal {
  @include textButton-medium();
  min-height: 40px;
  padding: 9px 10px;
}

.button_size_flattened {
  @include textButton-medium();
  min-height: 32px;
  padding: 5px 10px;
}

.button_size_mini {
  @include textButton-flattened();
  min-height: 28px;
  padding: 3px 8px;
}

@include tablet() {
  .button_desktop-size_oversize {
    @include textButton-oversize();
    min-height: 52px;
    padding: 15px 10px;
  }

  .button_desktop-size_normal {
    @include textButton-medium();
    min-height: 40px;
    padding: 9px 10px;
  }

  .button_desktop-size_flattened {
    @include textButton-medium();
    min-height: 32px;
    padding: 5px 10px;
  }

  .button_desktop-size_mini {
    @include textButton-flattened();
    min-height: 28px;
    padding: 3px 8px;
  }
}

.button_filled,
.button_outlined,
.button_ghost,
.button_size_mini {
  @include focus();
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &.button_disabled {
    background-color: $color-black-6;
    color: $color-black-20;
  }
}

.button_filled {
  background-color: $color-public-btn-filled;
  box-shadow: $basic-shadow;
  color: $color-black-5;

  &:hover:not(.button_disabled) {
    background-color: $color-public-btn-filled-hover;
  }

  &:active:not(.button_disabled) {
    background-color: $color-public-btn-filled-active;
    box-shadow: none;
  }

  &.button_disabled {
    background-image: none;
    box-shadow: none;
  }
}

.button_outlined {
  background-color: $color-black-0;
  border: 1px solid $color-black-10;
  color: $color-theme-primary;

  &:hover:not(.button_disabled) {
    border-color: $color-black-30;
    color: $color-theme-secondary;
  }

  &:active:not(.button_disabled) {
    border-color: $color-theme-variant;
    color: $color-theme-variant;
  }

  &.button_disabled {
    border: none;
  }
}

.button_ghost {
  background-color: $color-public-btn-ghost;
  color: $color-theme-primary;

  &:hover:not(.button_disabled) {
    background-color: $color-public-btn-ghost-hover;
    color: $color-theme-secondary;
  }

  &:active:not(.button_disabled) {
    background-color: $color-public-btn-ghost-active;
    color: $color-theme-variant;
  }

  &.button_disabled {
    background-image: none;
  }
}

.button__icon {
  svg {
    display: block;
  }
}

.button_with-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 44px;
  padding-right: 44px;
  position: relative;

  .button__wrapper {
    align-items: center;
    display: grid;
    gap: 12px;
    grid-gap: 12px;
    grid-template-columns: max-content auto;
    justify-content: center;
    width: 100%;
  }
}

.button_disabled {
  cursor: default;
  pointer-events: none;
}
