@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/breakpoints.scss";

.checkbox {
  position: relative;
  z-index: 1;
}

.checkbox__input {
  height: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.checkbox__texts {
  @include textBody();
  align-items: center;
  display: grid;
  gap: 6px;
  grid-auto-flow: column;
  justify-content: start;

  @include tablet() {
    @include textAccent();
  }
}

.checkbox__texts_with-links {
  line-height: 24px;

  .checkbox__texts-item_main {
    padding-right: 6px;
  }
}

.checkbox__texts-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
}

.checkbox__label {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  justify-content: start;
}

.checkbox__link {
  color: $color-theme-secondary;
  font-weight: $font-weight-medium;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-decoration: underline;

  &:hover,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}

.checkbox__icon {
  box-sizing: border-box;
  display: flex;
  height: 24px;
  padding: 2px;
  width: 24px;
}

.checkbox__icon-box {
  background: $color-black-0;
  border: 1px solid $color-borders-basic;
  border-radius: 4px;
  box-sizing: border-box;
  height: 20px;
  justify-self: center;
  position: relative;
  transition: all ease-in-out $transition-time;
  width: 20px;

  &::before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out $transition-time;
    width: 40px;
    z-index: -1;
  }

  &::after {
    border: none;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.checkbox:not(.checkbox_disabled) {
  .checkbox__icon-box:hover {
    border-color: $color-black-60;

    &::before {
      background: $color-black-5;
    }
  }
}

.checkbox__input:focus-visible + .checkbox__icon {
  .checkbox__icon-box {
    border-color: $color-black-60;

    &::before {
      background: $color-black-15;
    }
  }
}

// CHECKED
.checkbox:not(.checkbox_disabled).checkbox_checked {
  .checkbox__icon-box {
    &::after {
      background: transparent;
      border-bottom: 1px solid $color-black-75;
      border-left: 1px solid $color-black-75;
      height: 5px;
      transform: translate(-50%, -50%) rotate(-45deg) translate(1px, -1px);
      width: 10px;
    }
  }
}

// SEMI
.checkbox:not(.checkbox_disabled).checkbox_semi {
  .checkbox__icon-box {
    &::after {
      background: transparent;
      border-radius: 1px;
      height: 8px;
      transform: translate(-50%, -50%);
      width: 8px;
    }
  }
}

.checkbox:not(.checkbox_disabled).checkbox_semi.checkbox_checked {
  .checkbox__icon-box {
    &::after {
      background: $color-black-75;
    }
  }
}

// ERROR
.checkbox_error:not(.checkbox_disabled) {
  .checkbox__icon-box {
    border-color: $color-red-light;

    &::after {
      background-color: $color-red;
    }
  }

  .checkbox__icon-box:hover {
    border-color: $color-red;

    &::before {
      background: rgba($color: $color-red, $alpha: 0.06);
    }
  }

  .checkbox__input:focus-visible + .checkbox__icon {
    .checkbox__icon-box {
      border-color: $color-red;

      &::before {
        background: rgba($color: $color-red, $alpha: 0.1);
      }
    }
  }
}

.checkbox_error:not(.checkbox_disabled).checkbox_checked {
  .checkbox__icon-box {
    border-color: $color-red-light;

    &::after {
      border-color: $color-red;
    }
  }

  .checkbox__icon-box:hover {
    border-color: $color-red;
  }
}

.checkbox_error:not(.checkbox_disabled).checkbox_semi {
  .checkbox__icon-box {
    border-color: $color-red-light;
  }

  .checkbox__icon-box:hover {
    border-color: $color-red;
  }
}

.checkbox_error:not(.checkbox_disabled).checkbox_semi.checkbox_checked {
  .checkbox__icon-box::after {
    background-color: $color-red;
  }
}

// DISABLED
.checkbox_disabled {
  .checkbox__texts {
    color: $color-black-20;
  }

  .checkbox__label {
    cursor: default;
  }

  .checkbox__link {
    cursor: default;
    pointer-events: none;
  }

  .checkbox__icon-box {
    background: $color-black-6;
    border-color: $color-black-6;
  }
}

.checkbox_disabled.checkbox_checked {
  .checkbox__icon-box {
    &::after {
      background: transparent;
      border-bottom: 1px solid $color-black-20;
      border-left: 1px solid $color-black-20;
      height: 5px;
      transform: translate(-50%, -50%) rotate(-45deg) translate(1px, -1px);
      width: 10px;
    }
  }
}

.checkbox_disabled.checkbox_semi {
  .checkbox__icon-box {
    &::after {
      background: $color-black-20;
      border-radius: 1px;
      height: 8px;
      transform: translate(-50%, -50%);
      width: 8px;
    }
  }
}
