@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.map-view-page {
  display: grid;
}

.map-view-page__content {
  @include laptop() {
    display: flex;
  }
}

.map-view-page__list-wrapper {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 8px 16px 24px;
  word-break: break-word;

  @include tablet() {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include laptop() {
    padding: 12px 32px;
  }
}

.map-view-page__list-count {
  @include textBody($color: $color-black-50);
  @include reset-heading();
}

.map-view-page__list-count-value {
  color: $color-text-body;
  font-weight: $font-weight-bold;
}

.map-view-page__map {
  flex-grow: 1;
}
