@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.address-input__autocomplete-wrapper {
  position: relative;

  input::placeholder {
    color: transparent;
  }
}

// google-maps autocomplete
.pac-container {
  /* stylelint-disable declaration-no-important */
  left: 0 !important;
  top: calc(100% + 3px) !important;
  width: 100% !important;
  z-index: 1002;

  body &::after {
    background: none;
    content: none;
    height: 0;
  }
}

.pac-item {
  @include textBody();
  background-color: $color-black-5;
  cursor: pointer;
  padding: 9px 12px 10px;

  .pac-icon {
    display: none;
  }
}
