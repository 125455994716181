@import "src/scss/mixins/padding.scss";
@import "src/scss/mixins/breakpoints.scss";

.header {
  @include layout-padding();
  align-items: center;
  display: grid;
  gap: 32px;
  grid-auto-flow: column;
  justify-items: center;
  min-height: 56px;
  padding: 8px 0 2px;
}

.header_desktop {
  gap: 16px;
  min-height: 82px;
  padding-bottom: 8px;
  padding-top: 24px;
}

.header_with-buttons {
  justify-content: space-between;
}

.header_full-width {
  @include laptop() {
    max-width: 100%;
  }
}

.header__nav {
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
}

.header__phone-button {
  padding-left: 34px;
  padding-right: 34px;
}
