@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/padding.scss";
@import "src/scss/mixins/text.scss";

.footer {
  align-items: end;
  box-sizing: border-box;
  display: grid;
  width: 100%;
}

.footer_light {
  padding: 32px 16px 20px;

  .footer__logo-text {
    filter: $readability-filter;
  }
}

.footer_dark {
  background: $color-primary-light-basic;
  padding: 24px 0;

  .footer__logo-text {
    color: $color-black-75;
  }

  .footer__container {
    @include layout-padding();
    display: grid;
    gap: 24px;
    grid-auto-flow: row;
  }
}

.footer__content {
  display: grid;
  gap: 20px 4px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  width: 100%;
}

.footer__content_many {
  gap: 24px;
  grid-template-columns: max-content 1fr;
}

.footer__logo {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, max-content);
  width: max-content;
}

.footer__logo-text {
  color: $color-black-5;
  display: block;
  height: 11px;
  width: 124px;
}

.footer__logo-link {
  @include textAccent($weight: $font-weight-bold);
  color: $color-theme-primary;
}

@include desktop() {
  .footer_light {
    padding: 40px 32px;
  }

  .footer_dark {
    padding: 40px 0;

    .footer__container {
      gap: 28px;
      justify-self: center;
    }
  }

  .footer__content {
    align-items: center;
    gap: 23px;
    grid-template-columns: 1fr auto minmax(max-content, 84px);

    &:not(.footer__content_two-lines-desktop) {
      .footer__organization-links {
        align-items: center;
        grid-column: 2/3;
        grid-row: 1;
      }
    }
  }

  .footer__content_many {
    grid-template-columns: 1fr auto auto;
  }

  .footer__logo-img {
    height: 40px;
    width: 40px;
  }

  .footer__logo-text {
    height: 16px;
    width: 170px;
  }
}
