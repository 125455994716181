@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";

.reservation-buttons__checkbox-wrapper {
  background: $color-primary-light-alpha;
  border-radius: 8px;
  padding: 12px;

  @include tablet() {
    padding: 24px;
  }
}

.reservation-buttons__buttons-wrapper {
  display: grid;
  gap: 20px;
  justify-self: center;
  width: 260px;

  @include tablet() {
    grid-template-columns: 1fr 1fr;
    padding-top: 8px;
    width: 100%;
  }
}
