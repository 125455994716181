@import "src/scss/mixins/breakpoints.scss";

.find-another-lot-button {
  min-width: 240px;
  padding-left: 32px;
  padding-right: 32px;

  @include tablet() {
    min-width: auto;
    padding-left: 29px;
    padding-right: 29px;
  }
}
