@import "src/scss/variables.scss";
@import "src/scss/mixins/padding.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.lot-page {
  @include layout-padding();
  align-content: start;
  display: grid;
  gap: 32px;
  min-height: 100%;
  padding: 24px 0 0;

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
  }

  @include laptop() {
    gap: 32px 80px;
    grid-template-columns: 1fr 440px;
    padding: 36px 32px 24px;
  }
}

.lot-page__title-wrapper {
  display: grid;
  gap: 12px;
}

.lot-page__title {
  @include textHeadline5($weight: $font-weight-bold);
  @include reset-heading();
  text-align: center;

  @include laptop() {
    @include textHeadline3($weight: $font-weight-bold);
    line-height: 32px;
    text-align: left;
  }
}

.lot-page__price {
  @include textSubtitle();
  @include reset-description();
  line-height: 24px;
  text-align: center;

  @include laptop() {
    font-weight: $font-weight-medium;
    text-align: left;
  }
}

.lot-page__info-wrapper {
  align-content: start;
  display: grid;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;

  @include tablet() {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include laptop() {
    gap: 32px;
    padding-left: 0;
    padding-right: 0;
  }
}

.lot-page__info {
  align-content: start;
  display: grid;
  gap: 16px;
}

.lot-page__description {
  @include textBody();
  @include reset-description();
  text-align: justify;
  white-space: pre-line;
  word-break: break-word;

  @include laptop() {
    @include textAccent();
  }
}

.lot-page__location-section {
  align-content: start;
  display: grid;
  gap: 12px;
  justify-items: center;

  @include laptop() {
    gap: 20px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-items: start;
  }
}

.lot-page__sub-title {
  @include textSubtitle($weight: $font-weight-bold);
  @include reset-heading();
  text-align: center;

  @include laptop() {
    text-align: left;
  }
}

.lot-page__sub-title_location {
  @include laptop() {
    line-height: 24px;
  }
}

.lot-page__location-content {
  display: grid;
  gap: 8px;
  justify-items: center;

  @include laptop() {
    gap: 12px;
    justify-items: start;
  }
}

.lot-page__buttons-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  max-width: 328px;
  padding-top: 16px;
  width: 100%;

  @include laptop() {
    max-width: none;
    padding-top: 0;
  }
}

.lot-page__location-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  justify-content: center;

  @include laptop() {
    justify-content: start;
    row-gap: 12px;
  }

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    gap: 0;
  }
}

.lot-page__location-row_multiple {
  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    .lot-page__labeled-value {
      margin-bottom: 8px;
    }
  }

  @include laptop() {
    @supports not (translate: 10px) {
      // safari below 14.1, safari ios below 14.5
      .lot-page__labeled-value {
        margin-bottom: 12px;
      }
    }
  }
}

.lot-page__labeled-value {
  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    margin-right: 20px;
  }
}

.lot-page__reservation-section {
  align-self: start;
  border-top: 1px solid $color-black-10;
  display: grid;
  gap: 28px;
  justify-items: center;
  padding: 24px 24px 0;

  @include laptop() {
    border: 1px solid $color-black-10;
    border-radius: 8px;
    justify-items: start;
    padding: 24px;
  }
}

.lot-page__heading {
  display: grid;
  gap: 8px;
}

.lot-page__reservation-button {
  min-width: 240px;
  text-align: center;
  word-break: break-word;
}

.lot-page__reservation-text {
  @include textAccent();
  @include reset-description();
  text-align: center;

  @include laptop() {
    text-align: left;
  }
}

.lot-page__map-wrapper {
  min-height: 320px;

  @include tablet() {
    min-height: 440px;
  }

  @include laptop() {
    border-radius: 8px;
    overflow: hidden;
  }
}

.lot-page__button {
  display: grid;
  grid-auto-flow: column;
  min-width: 106px;
  padding: 10px 12px;
  text-align: center;
  word-break: break-word;

  .button__icon {
    position: static;
    transform: none;
  }

  @include laptop() {
    min-width: auto;
    padding: 9px 32px;

    .button__label {
      display: none;
    }

    &::after {
      content: attr(data-label);
    }
  }
}

.lot-page__gallery {
  display: contents;

  @include laptop() {
    display: grid;
    gap: 40px;
  }
}

.lot-page__column-wrapper {
  display: contents;

  @include laptop() {
    align-self: start;
    display: grid;
    gap: 32px;
    position: sticky;
    top: 40px;
  }
}
