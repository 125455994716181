@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

.lot-details {
  @include reset-list();
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    gap: 0;
  }
}

.lot-details__item {
  @include reset-list-item();
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: start;

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    padding-bottom: 4px;
    padding-right: 16px;
  }
}

.lot-details__item_wide {
  width: 100%;
}

.lot-details__value {
  @include textBody();
  word-break: break-word;
}
