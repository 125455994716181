@import "src/scss/variables.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.skip-link {
  @include reset-link();
  background-color: $color-afs-secondary;
  border: 0;
  box-sizing: border-box;
  clip: rect(0, 0, 0, 0);
  display: block;
  height: 1px;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 1px;
  word-wrap: normal;
  z-index: 999999;

  &:focus {
    border: 1px solid $color-afs-light;
    clip: auto;
    height: auto;
    overflow: auto;
    padding: 10px 12px;
    width: auto;
  }
}

.skip-link__wrapper {
  align-content: start;
  display: grid;
  gap: 6px;
  justify-items: center;
}

.skip-link__text {
  text-align: center;
}

.skip-link__text_main {
  @include textBody($color: $color-primary-light-basic);
}

.skip-link__text_additional {
  @include textCaption($color: $color-primary-light-basic);
  align-items: center;
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
}

.skip-link__text-icon {
  height: 12px;
  width: 12px;
}
