// FONT FAMILY
$font-main: "DM Sans", sans-serif;
$font-second: "Arimo", sans-serif;
$font-logo-title: "PT Sans Narrow", "Arial", sans-serif;
$font-logo-subtitle: "Mako", "Arial", sans-serif;

// FONT SIZES
$font-size-tiny: 8px;
$font-size-caption: 12px;
$font-size-body: 14px;
$font-size-accent: 16px;
$font-size-subtitle: 20px;
$font-size-headline-5: 24px;
$font-size-headline-4: 28px;
$font-size-headline-3: 32px;
$font-size-headline-2: 52px;
$font-size-headline-1: 92px;
$font-logo-main: 36px;
$font-logo-main-mobile: 25px;
$font-logo-main-mobile-subtitle: 18px;

// FONT WEIGHTS
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// THEME COLORS
$color-theme-assist: var(--color-theme-assist);
$color-theme-assist-rgb: var(--color-theme-assist-rgb);
$color-theme-primary: var(--color-theme-accent);
$color-theme-light: var(--color-theme-light);
$color-theme-secondary: var(--color-theme-secondary);
$color-theme-variant: var(--color-theme-variant);

// AFS COLORS
$color-afs-primary: #3b3b3b;
$color-afs-secondary: #6d4aff;
$color-afs-light: #bdadff;

// Button filled
$color-public-btn-filled: var(--color-public-btn-filled);
$color-public-btn-filled-rgb: var(--color-public-btn-filled-rgb);
$color-public-btn-filled-hover: var(--color-public-btn-filled-hover);
$color-public-btn-filled-active: var(--color-public-btn-filled-active);

// Button ghost
$color-public-btn-ghost: var(--color-public-btn-ghost);
$color-public-btn-ghost-hover: var(--color-public-btn-ghost-hover);
$color-public-btn-ghost-active: var(--color-public-btn-ghost-active);

// GREY COLORS
$color-black: #000000;
$color-black-90: #222222;
$color-black-75: #3b3b3b;
$color-black-60: #6f6f6f;
$color-black-50: #707070;
$color-black-45: #808080;
$color-black-40: #bfbfbf;
$color-black-30: #b9b9b9;
$color-black-25: #c2c2c2;
$color-black-20: #cdcdcd;
$color-black-10: #e5e5e5;
$color-black-15: #efefef;
$color-black-6: #f2f2f2;
$color-black-5: #f7f7f7;
$color-black-0: #ffffff;

// OTHER COLORS
$color-red: #d95151;
$color-red-light: #f9919a;
$color-red-extra-light: #ffcfcf;
$color-outline: #35cdfd;
$color-map-marker: #0087cf;

// PRIMARY LIGHT COLORS
$color-primary-light-basic: #fafafa;
$color-primary-light-alpha: #f6f6f6;
$color-primary-light-beta: #e8e8e8;

// TEXT COLORS
$color-text-body: $color-black-75;
$color-text-caption: $color-black-45;
$color-text-disabled: $color-black-20;
$color-text-light: $color-black-30;

// BORDERS COLORS
$color-borders-basic: #b0b0b0;

// BUTTON FOCUS
$button-focus: 0 0 0 1px $color-black-0, 0 0 0 3px $color-outline;

// TRANSITION TIME
$transition-time: 0.15s;
$transition-time-long: 0.3s;

// SHADOW
$basic-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
$readability-filter: drop-shadow(0 4px 28px rgba(0, 0, 0, 0.16))
  drop-shadow(0 2px 6px rgba(0, 0, 0, 0.16));

// BREAKPOINTS
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1280px;

// COMPONENTS
$max-layout-width: 980px;
