@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.notes-section {
  display: grid;
  gap: 16px;
}

.notes-section__title {
  @include reset-heading();
  @include textSubtitle($weight: $font-weight-bold);
}

.notes-section__text {
  @include reset-description();
  @include textAccent($font-weight-medium);
  white-space: pre-wrap;
  word-break: break-word;
}
