@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.thumbs {
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  position: relative;

  @include laptop() {
    height: 516px;
    left: 32px;
    max-height: calc(var(--vh, 1vh) * 80);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-height: 480px) and (max-width: ($laptop-width - 1)) {
    display: none;
  }
}

.thumbs__wrapper {
  @include reset-list();
  align-content: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  height: 100%;
  justify-content: start;
  overflow: auto;
  padding: 0 0 0 16px;
  transition: transform $transition-time;
  width: 100%;

  &::after {
    content: "";
    display: block;
    height: 100%;

    // fixes safari padding
    margin-left: -12px;
    width: 16px;
  }

  @include tablet() {
    padding-left: 100px;

    &::after {
      width: 100px;
    }
  }

  @include laptop() {
    align-content: start;
    grid-auto-flow: row;
    padding: 0;

    &::after {
      display: none;
    }
  }
}

.thumbs__wrapper_centered_horizontal {
  justify-content: center;
}

@include laptop() {
  .thumbs__wrapper_centered_vertical {
    align-content: center;
  }
}
