@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.footer-links {
  @include reset-list();
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  grid-column: 1/3;

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    gap: 0;
  }
}

.footer-links_theme_dark {
  .footer-links__item-link {
    color: $color-theme-primary;
  }
}

.footer-links_theme_light {
  .footer-links__item {
    filter: $readability-filter;
  }
}

.footer-links__item {
  @include reset-list-item();
}

.footer-links__item-link {
  @include textBody($color: $color-black-5, $weight: $font-weight-bold);
  opacity: 0.8;
  word-break: break-word;

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    margin-right: 20px;
  }
}
