@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.burial-types-list {
  @media (max-width: 359px) {
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;

    .burial-types-list__items {
      grid-template-columns: 1fr;
    }
  }
}

.burial-types-list__items {
  @include reset-list();
  align-content: start;
  display: grid;
  gap: 16px;
  grid-template-columns: 328px;

  @include tablet() {
    gap: 24px 40px;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, minmax(280px, 1fr));
    grid-template-rows: 1fr 1fr;
    max-width: 800px;

    &.burial-types-list__items_single-item,
    &.burial-types-list__items_two-items {
      grid-template-rows: 1fr;
    }

    &.burial-types-list__items_single-item {
      grid-template-columns: 280px;
    }
  }
}

.burial-types-list__item {
  @include reset-list-item();
}
