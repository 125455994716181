@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.main-page {
  position: relative;
}

.main-page__wrapper {
  display: grid;
  gap: 20px;
  grid-template-rows: 1fr auto;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;

  @include tablet() {
    gap: 0;
  }
}

.main-page__bg {
  background-color: #485331;
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.main-page__content {
  align-content: center;
  display: grid;
  gap: 20px;
  justify-items: center;
  padding: 24px 16px 0;

  @include tablet() {
    gap: 60px;
    padding-top: 90px;
  }
}

.main-page__cemetery-data {
  display: grid;
  gap: 20px;
  justify-items: center;

  @include tablet() {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
    gap: 24px;
  }
}

.main-page__info {
  display: grid;
  gap: 8px;
  max-width: 296px;

  @include tablet() {
    max-width: 600px;
  }
}

.main-page__title {
  @include reset-heading();
  @include textHeadline3($weight: $font-weight-bold, $color: $color-black-5);
  filter: $readability-filter;
  padding: 0 36px;
  text-align: center;

  @include tablet() {
    @include textHeadline2($weight: $font-weight-bold, $color: $color-black-0);
    padding: 0;
  }
}

.main-page__description {
  @include reset-heading();
  @include textBody($weight: $font-weight-regular, $color: $color-black-5);
  filter: $readability-filter;
  text-align: center;

  @include tablet() {
    @include textSubtitle($weight: $font-weight-regular, $color: $color-black-0);
  }
}

@media (max-height: 880px) {
  .main-page__wrapper {
    gap: 0;
  }

  .main-page__content {
    gap: 32px;
    padding-top: 8px;
  }

  .main-page__cemetery-data {
    gap: 8px;
  }
}
