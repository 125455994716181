@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.social {
  @include reset-list();
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 32px);
  justify-content: end;

  @include desktop() {
    gap: 20px;
    grid-auto-flow: column;
  }
}

.social_many {
  gap: 42px;
  grid-column: 1/3;
  grid-row: 3;
  justify-content: start;

  @include desktop() {
    gap: 20px;
    grid-column: auto;
    grid-row: auto;
    justify-content: end;
  }
}

.social_theme_dark {
  .social__icon {
    color: $color-theme-primary;
  }
}

.social_theme_light {
  .social__icon {
    color: $color-black-5;
  }
}

.social__item {
  @include reset-list-item();
}

.social__link {
  align-content: center;
  display: grid;
  justify-content: center;
}

.social__icon {
  display: block;
  height: 32px;
  opacity: 0.8;
  width: 32px;
}
