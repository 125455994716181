@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.popup {
  align-items: center;
  animation: $transition-time forwards fadeIn;
  background-color: rgba($color: $color-black, $alpha: 0.45);
  box-sizing: border-box;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1100;
}

.popup__wrapper {
  align-content: start;
  animation: $transition-time forwards fadeIn;
  background-color: $color-black-0;
  box-shadow: $basic-shadow;
  box-sizing: border-box;
  display: grid;
  gap: 40px;
  justify-items: center;
  min-height: 100%;
  outline: none;
  overflow: auto;
  padding: 40px 16px;
  width: 100%;

  @include tablet() {
    border-radius: 8px;
    gap: 20px;
    min-height: auto;
    padding: 20px;
    width: 300px;
  }
}

.popup__header {
  display: grid;
  gap: 8px;
  width: 100%;

  @include tablet() {
    gap: 16px;
  }
}

.popup__title {
  @include reset-description();
  @include textHeadline5($weight: $font-weight-bold);
  text-align: center;

  @include tablet() {
    @include textHeadline3($weight: $font-weight-bold, $color: $color-black-90);
    line-height: 32px;
  }
}

.popup__text-wrapper {
  display: grid;
  gap: 4px;

  @include tablet() {
    .popup__text {
      line-height: 16px;
    }
  }
}

.popup__text {
  @include reset-description();
  @include textBody();
  text-align: center;
}

.popup__button {
  min-width: 260px;
  word-break: break-word;

  @include tablet() {
    min-width: 279px;
  }
}

.popup_hiding {
  animation: $transition-time both fadeOut;

  .popup__wrapper {
    animation: $transition-time both fadeOut;
  }
}

.popup_gallery {
  .popup__wrapper {
    align-content: start;
    background: $color-black-75;
    border-radius: 0;
    box-sizing: border-box;
    display: grid;
    gap: 16px;
    height: 100%;
    justify-items: center;
    padding: 90px 0 16px;
    position: relative;
    width: 100%;

    @include laptop() {
      align-content: center;
      padding: 50px 0;
    }
  }

  .popup__close-button {
    background-color: rgba($color-black-45, 0.3);
    border-radius: 50%;
    height: 44px;
    left: 16px;
    position: absolute;
    top: 20px;
    width: 44px;
    z-index: 999;

    @include laptop() {
      color: $color-black-5;
      height: 48px;
      left: calc(100% - 80px);
      top: 32px;
      width: 48px;
    }
  }

  .popup__close-button-icon {
    color: $color-black-20;
    height: 24px;
    width: 24px;

    @include laptop() {
      color: $color-black-5;
    }
  }
}

.popup_warn,
.popup_cancel-request,
.popup_success-request {
  .popup__wrapper {
    align-content: center;
    border-radius: 0;
    display: grid;
    gap: 40px;
    height: 100%;
    justify-items: center;
    padding: 16px;
    width: 100%;

    @include tablet() {
      border-radius: 8px;
      gap: 32px;
      height: auto;
      padding: 40px;
      width: 520px;
    }
  }
}

.popup_cancel-request {
  .popup__buttons-wrapper {
    display: grid;
    gap: 12px;

    @include tablet() {
      gap: 20px;
    }
  }
}

.popup_success-request {
  .popup__wrapper {
    @include tablet() {
      gap: 40px;
      width: 570px;
    }

    @media (max-height: 568px) and (orientation: landscape) {
      align-content: start;
      height: 100%;
    }
  }

  .popup__main {
    display: grid;
    gap: 28px;
  }

  .popup__icon {
    color: $color-black-25;
  }

  .popup__description {
    @include reset-description();
    @include textBody($weight: $font-weight-regular, $color: $color-black-50);
    background: $color-primary-light-alpha;
    border-radius: 8px;
    padding: 16px;
    text-align: justify;

    @include tablet() {
      @include textAccent($weight: $font-weight-regular, $color: $color-black-50);
      padding: 24px;
    }
  }
}

.popup_success-request_contacts {
  @include tablet() {
    .popup__wrapper {
      width: 520px;
    }
  }
}

.popup_share {
  .popup__wrapper {
    gap: 40px;
  }

  .popup__title {
    @include textSubtitle($weight: $font-weight-bold);
  }

  .popup__items-wrapper {
    align-content: start;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(64px, max-content));
    justify-content: center;
    width: 100%;
    word-break: break-word;
  }

  .popup__button {
    min-width: 200px;

    @include tablet() {
      min-width: 200px;
    }
  }

  @include tablet() {
    .popup__wrapper {
      padding: 40px 20px;
      width: 496px;
    }
  }
}
