@import "../variables.scss";

@mixin text($size, $weight, $color, $font-family: $font-main) {
  color: $color;
  font-family: $font-family;
  font-size: $size;
  font-weight: $weight;
  line-height: 1.2;
}

@mixin textCaption($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-caption, $weight, $color);
  letter-spacing: 0.24px;
  line-height: 16px;
}

@mixin textBody($weight: $font-weight-regular, $color: $color-text-body, $font-family: $font-main) {
  @include text($font-size-body, $weight, $color, $font-family);
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textAccent($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-accent, $weight, $color);
  letter-spacing: 0.3px;
  line-height: 24px;
}

@mixin textSubtitle($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-subtitle, $weight, $color);
  letter-spacing: 0.25px;
  line-height: 28px;
}

@mixin textHeadline5($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-headline-5, $weight, $color);
  letter-spacing: 0.25px;
  line-height: 32px;
}

@mixin textHeadline4($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-headline-4, $weight, $color);
  letter-spacing: 0.25px;
  line-height: 40px;
}

@mixin textHeadline3($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-headline-3, $weight, $color);
  letter-spacing: 0.25px;
  line-height: 42px;
}

@mixin textHeadline2($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-headline-2, $weight, $color);
  letter-spacing: 0.4px;
  line-height: 62px;
}

@mixin textHeadline1($weight: $font-weight-regular, $color: $color-text-body) {
  @include text($font-size-headline-1, $weight, $color);
  letter-spacing: 0.35px;
  line-height: 100px;
}

@mixin textButton($size) {
  @include text($size: $size, $weight: $font-weight-bold, $color: $color-text-body);
  letter-spacing: 0.48px;
  line-height: 20px;
}

@mixin textButton-oversize() {
  @include textButton($size: $font-size-accent);
}

@mixin textButton-medium() {
  @include textButton($size: $font-size-body);
}

@mixin textButton-flattened() {
  @include textButton($size: $font-size-caption);
}
