@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/breakpoints.scss";

.not-found-page {
  background-color: $color-primary-light-basic;
  display: grid;
  grid-template-rows: 1fr auto;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

.not-found-page__wrapper {
  align-content: center;
  align-items: center;
  display: grid;
  gap: 40px;
  grid-template-rows: auto auto;
  justify-items: center;
  padding-bottom: calc(var(--vh, 1vh) * 8);
  padding-top: calc(var(--vh, 1vh) * 4);

  @include tablet() {
    padding-top: calc(var(--vh, 1vh) * 10);
  }
}

.not-found-page__content {
  display: grid;
  gap: 48px;
  justify-items: center;
}

.not-found-page__logo {
  height: 120px;
  max-width: 140px;
  width: auto;

  @include tablet() {
    height: 200px;
    max-width: 200px;
  }
}

.not-found-page__text {
  @include textSubtitle($font-weight-medium);
  @include reset-heading();
  display: grid;
  gap: 8px;
  text-align: center;

  @include tablet() {
    gap: 20px;
  }
}

.not-found-page__text-accent {
  @include textHeadline1($font-weight-bold);
  font-size: 64px;
  line-height: 80px;

  @include tablet() {
    font-size: $font-size-headline-1;
    line-height: 100px;
  }
}

.not-found-page__button {
  text-align: center;
  width: 240px;

  @include tablet() {
    width: 260px;
  }
}
