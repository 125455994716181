@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.lot-gallery {
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-items: center;
  max-width: calc(100vw - 16px);
  overflow: auto;
  padding: 0 0 0 16px;
  transform: translate3d(0, 0, 0); // fix IOS < 15 gallery flicker

  &::after {
    // fixes safari padding
    content: "";
    display: block;
    height: 100%;
    margin-left: -12px;
    width: 16px;
  }

  @include tablet() {
    gap: 20px;
    max-width: calc(100vw - 32px);
    padding-left: 32px;

    &::after {
      margin-left: -20px;
      width: 32px;
    }
  }

  @include laptop() {
    gap: 40px;
    grid-auto-flow: row;
    max-width: 100%;
    overflow: visible;
    padding: 0;

    &::after {
      display: none;
    }
  }
}

.lot-gallery_count_single {
  padding-right: 16px;

  &::after {
    display: none;
  }

  @include tablet() {
    padding-right: 32px;
  }

  @include laptop() {
    padding-right: 0;
  }
}

.lot-gallery_count_double {
  @media (min-width: 508px) and (max-width: ($laptop-width - 1)) {
    justify-content: center;
  }
}

.lot-gallery__photo {
  @include reset-button();
  @include focus();
  border-radius: 8px;
  height: 320px;
  overflow: hidden;
  position: relative;
  width: 240px;

  @include tablet() {
    height: 440px;
    width: 330px;
  }

  @include laptop() {
    height: 576px;
    width: 440px;
  }
}

.lot-gallery__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
