@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/reset.scss";

body {
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  text-size-adjust: none;
}

.app {
  min-height: calc(var(--vh, 1vh) * 100);
}

.app_err {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  padding-bottom: 80px;
}

.app__error {
  @include textSubtitle($weight: $font-weight-bold, $color: $color-red);
  @include reset-description();
}

.app__error-list {
  @include textBody($color: $color-red);
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}
