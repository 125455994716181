@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.reservation-page {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 32px;
  margin: 0 auto;
  max-width: 604px;
  padding: 20px 16px 0;

  @include tablet() {
    padding-bottom: 24px;
    padding-top: 36px;
  }
}

.reservation-page__notes_editable {
  @include tablet() {
    padding-bottom: 8px;
  }
}
