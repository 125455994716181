@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";

.range {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 20px auto;
}

.range__input {
  height: 40px;
  min-width: 100px;

  .input__field,
  .input__field::placeholder {
    font-size: $font-size-body;
  }
}

.range__divider {
  @include textBody();
  text-align: center;
}
