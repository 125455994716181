@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.burial-type {
  @include reset-link();
  align-items: center;
  backdrop-filter: blur(8px);
  background: linear-gradient(
    270deg,
    rgba($color-red-extra-light, 0.1) 0%,
    rgba($color-red-extra-light, 0.06) 100%
  );
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba($color-black, 0.36), inset 0 0 1px 1px rgba($color-black-0, 0.12);
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 20px;
  grid-template-columns: 56px 1fr;
  height: 100%;
  padding: 12px 20px;
  position: relative;

  &:hover {
    background: rgba($color-red-extra-light, 0.16);
  }

  &:focus {
    outline: none;

    &::before {
      border: 2px solid $color-black-10;
      border-radius: 10px;
      bottom: -2px;
      content: "";
      left: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: -2px;
    }
  }
}

.burial-type__icon {
  color: $color-black-30;
}

.burial-type__info {
  display: grid;
  gap: 4px;
  text-align: right;
  word-break: break-word;
}

.burial-type__category {
  @include textAccent($weight: $font-weight-regular, $color: $color-black-5);
  filter: $readability-filter;
  opacity: 0.8;
}

.burial-type__name {
  @include textSubtitle($weight: $font-weight-bold, $color: $color-black-5);
  filter: $readability-filter;
}
