@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/focus.scss";

.icon-button {
  @include focus();
  align-items: center;
  background: none;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  transition: all $transition-time;
  width: 20px;

  svg {
    display: block;
  }
}

.icon-button_reset-link {
  display: inline-flex;
}

.icon-button_size_oversize {
  height: 52px;
  width: 52px;
}

.icon-button_size_normal {
  height: 40px;
  width: 40px;
}

.icon-button_size_flattened {
  height: 32px;
  width: 32px;
}

.icon-button_size_mini {
  height: 28px;
  width: 28px;
}

.icon-button_filled,
.icon-button_ghost,
.icon-button_outlined {
  border-radius: 8px;

  &.icon-button_disabled {
    background-color: $color-black-6;
    color: $color-black-20;
  }
}

.icon-button_filled {
  background-color: $color-public-btn-filled;
  box-shadow: $basic-shadow;
  color: $color-black-5;

  &:hover:not(.icon-button_disabled) {
    background-color: $color-public-btn-filled-hover;
  }

  &:active:not(.icon-button_disabled) {
    background-color: $color-public-btn-filled-active;
    box-shadow: none;
  }

  &.icon-button_disabled {
    background-image: none;
    box-shadow: none;
  }
}

.icon-button_outlined {
  border: 1px solid $color-black-10;
  color: $color-theme-primary;

  &:hover:not(.icon-button_disabled) {
    border-color: $color-black-30;
    color: $color-theme-secondary;
  }

  &:active:not(.icon-button_disabled) {
    border-color: $color-theme-variant;
    color: $color-theme-variant;
  }

  &.icon-button_disabled {
    border: none;
  }
}

.icon-button_ghost {
  background-color: $color-public-btn-ghost;
  color: $color-theme-primary;

  &:hover:not(.icon-button_disabled) {
    background-color: $color-public-btn-ghost-hover;
    color: $color-theme-secondary;
  }

  &:active:not(.icon-button_disabled) {
    background-color: $color-public-btn-ghost-active;
    color: $color-theme-variant;
  }

  &.icon-button_disabled {
    background-image: none;
  }
}

.icon-button_disabled {
  color: $color-black-20;
  cursor: default;
  pointer-events: none;
}
