@import "src/scss/mixins/breakpoints.scss";

$mobile-padding: 16px;
$tablet-padding: 40px;
$desktop-padding: 32px;

@mixin layout-padding() {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $max-layout-width + $mobile-padding * 2;
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
  width: 100%;

  @include tablet() {
    max-width: $max-layout-width + $tablet-padding * 2;
    padding-left: $tablet-padding;
    padding-right: $tablet-padding;
  }

  @include laptop() {
    max-width: $max-layout-width + $desktop-padding * 2;
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}
