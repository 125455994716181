@import "src/scss/variables.scss";
@import "src/scss/animations.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.location-screen {
  animation: fadeIn $transition-time ease-in-out forwards;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.location-screen_hide {
  animation: fadeOut $transition-time ease-in-out forwards;
}

.location-screen__wrapper {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.location-screen__header {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 48px;
  justify-content: start;
  min-height: 80px;
  padding: 16px;

  @include tablet() {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include laptop() {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.location-screen__header-icon {
  background-color: $color-black-5;
  border-radius: 50%;
  color: $color-text-body;
  height: 48px;
  width: 48px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.location-screen__title {
  @include reset-heading();
  @include textSubtitle($weight: $font-weight-medium);
  word-break: break-word;
}

.location-screen__body {
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  position: relative;
}
