@import "src/scss/variables.scss";
@import "src/scss/mixins/focus.scss";
@import "src/scss/mixins/text.scss";

.chip {
  @include focus();
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-grid;
  text-align: center;
  transition: border-color, background-color $transition-time;
  white-space: nowrap;
}

.chip_size-regular {
  @include textBody();
  min-height: 32px;
  padding: 4px 16px;

  .chip__close-button-icon {
    height: 16px;
    width: 16px;
  }
}

.chip_size-flattened {
  @include textCaption();
  min-height: 24px;
  padding: 3px 12px;

  .chip__close-button {
    height: 16px;
    width: 16px;
  }

  .chip__close-button-icon {
    height: 14px;
    width: 14px;
  }
}

.chip_with-close {
  pointer-events: none;

  .chip__close-button {
    pointer-events: auto;
  }
}

.chip_outlined {
  background-color: $color-black-0;
  border: 1px solid $color-borders-basic;
  color: $color-black-75;

  &:hover:not(.chip_with-close) {
    background-color: $color-primary-light-basic;
    border-color: $color-black-60;
  }

  &:active:not(.chip_with-close) {
    background-color: $color-primary-light-alpha;
    border-color: $color-black-60;
  }

  .chip__close-button {
    border-radius: 50%;
    color: $color-black-45;

    &:hover {
      background-color: $color-primary-light-alpha;
      color: $color-black-75;
    }

    &:active {
      background-color: $color-primary-light-beta;
      color: $color-black-75;
    }
  }
}

.chip_filled {
  background-color: $color-theme-primary;
  color: $color-black-5;

  &:hover:not(.chip_with-close) {
    background-color: $color-theme-secondary;
  }

  &:active:not(.chip_with-close) {
    background-color: $color-theme-variant;
  }

  .chip__close-button {
    border-radius: 50%;
    color: $color-black-5;

    &:hover {
      background-color: $color-theme-light;
      color: $color-black-5;
    }

    &:active {
      background-color: $color-theme-variant;
      color: $color-black-5;
    }
  }
}

.chip_with-close_regular {
  gap: 8px;
  grid-template-columns: 1fr 20px;
  padding-right: 8px;
}

.chip_with-close_flattened {
  gap: 4px;
  grid-template-columns: 1fr 16px;
  padding-right: 4px;
}
