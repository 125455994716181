@import "src/scss/variables.scss";
@import "src/scss/mixins/text.scss";
@import "src/scss/mixins/breakpoints.scss";

.input {
  align-items: center;
  border: 1px solid $color-borders-basic;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  height: 52px;
  padding: 0 12px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 100%;

  &:hover {
    border-color: $color-black-45;
  }

  &:not(.input_disabled):focus-within {
    border-color: transparent;
    box-shadow: $button-focus;
  }

  svg {
    color: $color-black-75;
  }
}

.input__field {
  @include textAccent();
  border: none;
  height: 20px;
  outline: none;
  padding: 0;
  width: 100%;

  // noinspection CssInvalidPseudoSelector
  &::-webkit-contacts-auto-fill-button {
    /* stylelint-disable declaration-no-important */
    display: none !important;
    height: 0;
    margin: 0;
    pointer-events: none;
    visibility: hidden;
    width: 0;
  }

  &::placeholder {
    @include textAccent($color: $color-black-50);
    opacity: 1;
    text-transform: uppercase;
  }
}

.input_icon {
  gap: 8px;
  grid-template-columns: max-content 1fr;
  height: 40px;

  svg {
    color: $color-black-75;
  }

  &::placeholder {
    text-transform: none;
  }
}

.input__label {
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input_multi {
  height: auto;
  padding: 12px 0;
  position: relative;

  &.input_with-counter {
    padding: 12px 0 23px;
  }

  .input__textarea {
    @include textAccent();
    border: none;
    border-radius: inherit;
    line-height: 24px;
    outline: none;
    padding: 0 11px;
    resize: none;
  }
}

.input__counter {
  @include textCaption($color: $color-black-45);
  bottom: 8px;
  height: 16px;
  position: absolute;
  right: 12px;
  text-align: right;
}

.input_disabled svg {
  color: $color-black-20;
}

.input_flattened {
  background-color: $color-black-0;
  height: auto;
  min-height: 40px;
  padding: 0 8px;

  .input__field {
    @include textAccent();
    background-color: transparent;
  }

  .label_placeholder {
    top: 6px;
  }
}

@include tablet() {
  .input_desktop-flattened {
    background-color: $color-black-0;
    height: auto;
    min-height: 40px;
    padding: 0 8px;

    .input__field {
      @include textAccent();
      background-color: transparent;
    }

    .label_placeholder {
      top: 6px;
    }
  }

  .input_desktop-fullsize {
    height: 52px;
    padding: 0 12px;

    .label_placeholder {
      top: 12px;
    }
  }
}

.input_invalid {
  border-color: $color-red;

  &:hover {
    border-color: $color-red;
  }

  .input__field,
  svg {
    color: $color-red;
  }
}

.input_disabled .input__field,
.input_disabled .input__field::placeholder,
.input_disabled .input__value,
.input_disabled .input__textarea {
  background: $color-primary-light-alpha;
  color: $color-black-20;
}

.input_disabled {
  background-color: $color-primary-light-alpha;
  border-color: transparent;

  &:hover {
    border-color: transparent;
  }
}
