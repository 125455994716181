@import "src/scss/variables.scss";
@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";
@import "src/scss/mixins/text.scss";

.reservation-header {
  display: grid;
  gap: 8px;

  @include tablet() {
    gap: 20px;
  }
}

.reservation-header_confirmation {
  @include tablet() {
    gap: 16px;
  }
}

.reservation-header__title {
  @include reset-heading();
  @include textHeadline5($weight: $font-weight-bold);
  text-align: center;

  @include tablet() {
    @include textHeadline3($weight: $font-weight-bold, $color: $color-black-90);
    line-height: 32px;
    text-align: left;
  }
}

.reservation-header__description {
  @include reset-description();
  @include textBody($weight: $font-weight-regular, $color: $color-black-50);
  background: $color-primary-light-alpha;
  border-radius: 8px;
  padding: 16px;
  text-align: justify;

  @include tablet() {
    @include textAccent($weight: $font-weight-regular, $color: $color-black-50);
    padding: 24px;
  }
}

.reservation-header__text {
  @include reset-description();
  @include textBody();
  text-align: center;

  @include tablet() {
    @include textAccent();
    text-align: justify;
  }
}
