@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/reset.scss";

.map-lots-list {
  @include reset-list();
  box-sizing: border-box;
  padding-bottom: 60px;

  @include tablet() {
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;
  }

  @include laptop() {
    padding-left: 0;
    padding-right: 0;
  }
}

.map-lots-list__item {
  @include reset-list-item();
  position: relative;
}
