@import "src/scss/mixins/breakpoints.scss";
@import "src/scss/mixins/text.scss";

.map-sort {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;

  .select__button {
    justify-items: end;
    min-width: 130px;
  }

  .select__value {
    font-weight: $font-weight-bold;
  }

  @include tablet() {
    gap: 4px;
  }
}

.map-sort__label {
  @include textBody($color: $color-black-50);
}
